import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PointOperation } from '../components/PointOperation';

interface DetailProps extends RouteComponentProps<{id: string}> {
};

export class PointOperationDetail extends React.Component<DetailProps, {}> {
    render() {
        return (
            <div>
                <PointOperation id={this.props.match.params.id}/>
            </div>
        );
    }
}
