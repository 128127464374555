import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Auth } from './components/Auth';
import { Home } from './pages/Home';
import { PointOperationDetail } from './pages/PointOperationDetail';

import './App.css';

function App() {
    return (
        <BrowserRouter>
            <Auth>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/operations/:id' component={PointOperationDetail} />
                </Switch>
            </Auth>
        </BrowserRouter>
    );
}

export default App;
