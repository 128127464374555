import React from 'react';
import { Link } from "react-router-dom";
import { DeployUtil } from "casper-js-sdk";
import HomeBase from '../components/HomeBase';
import { getPointOperation } from '../libs/Firebase';
import { deployByUser } from '../libs/Firebase';
import { getUserInfo } from '../libs/Firebase';
import * as utils from '../libs/CasperUtils';
import {format} from 'date-fns';
import { FaPaperPlane } from "react-icons/fa"
import '../css/PointOperation.css';

interface PointOperationProps {
    id: string;
}
interface PointOperationState {
    data: {
        //使用する項目のみ定義
        id: string,
        operation_datetime: string,
        action_code: string,
        owner: number,
        owner_name: string,
        owner_account_hash: string,
        recipient: number,
        recipient_name: string,
        recipient_account_hash: string,
        amount: number,
        service_code: string,
        service_event_id: string,
        service_data_hash: string,
        deploy_id: string
    } | null;

    user: {
        id: string;
        email: string;
        point: number;
    } | null;
}

var service_code_to_name = new Map();
service_code_to_name.set("homebase", "ほめべーす");

export class PointOperation extends React.Component<PointOperationProps, PointOperationState> {
    constructor(props: PointOperationProps) {
        super(props);
        this.state = {
            data: null,
            user: null,
        };
    }

    async componentDidMount() {
        const point_operation = await getPointOperation(this.props.id);
        this.setState({
            data: point_operation,
        });

        const user_info = await getUserInfo();
        this.setState({
            user: user_info
        });
    }

    private async handleClick(id?: string, owner_account_hash?: string, recipient_account_hash?: string, amount?: number) {
        //パラメータのチェック
        if(!id || !owner_account_hash || !recipient_account_hash || !amount) {
            const message = "パラメータが不正です";
            alert(message);
            throw new Error(message);
        }

        const deploy = utils.createTransferFromDeploy(owner_account_hash, recipient_account_hash, amount);
        const deploy_json = JSON.stringify(DeployUtil.deployToJson(deploy));

        const result = await deployByUser(id, deploy_json);
        alert(`ポイントを送信しました ID=[${result.id}]`)
    }

    private getServiceName(service_code: string): React.ReactNode {
        if( !service_code_to_name.has(service_code) ) return service_code;
        return service_code_to_name.get(service_code);
    }

    render() {
        if (this.state.data === null || this.state.user === null ) {
            return (
                <p>読込中...</p>
            );
        }
        return (
            <div className='Point-operation-layout'>
                <div className='Point-operation-card'>
                    <h1 className='Point-operation-h1'>ポイント送信情報詳細</h1>
                    <table className='Point-operation-card_table'>
                        <tbody>
                            <tr><th>送信ID</th><td>{this.state.data.id}</td></tr>
                            <tr><th>日時</th><td>{format(Date.parse(this.state.data.operation_datetime), "yyyy/MM/dd HH:mm")}</td></tr>
                            <tr><th>送信元</th><td>{this.state.data.owner_name}</td></tr>
                            <tr><th>送信先</th><td>{this.state.data.recipient_name}</td></tr>
                            <tr><th>送信ポイント数</th><td>{this.state.data.amount}</td></tr>
                            <tr><th>保有ポイント数</th><td>{this.state.user.point}</td></tr>
                            <tr><th>対象サービス名</th><td>{this.getServiceName(this.state.data.service_code)}</td></tr>
                            {/*<tr><th>action_code</th><td>{this.state.data.action_code}</td></tr>
                            <tr><th>service_event_id</th><td>{this.state.data.service_event_id}</td></tr>
                            <tr><th>service_data_hash</th><td>{this.state.data.service_data_hash}</td></tr>
        <tr><th>deploy_id</th><td>{this.state.data.deploy_id}</td></tr>*/}
                        </tbody>
                    </table>
                    <div className='Point-operation-ly'>
                        <div className='Point-operation-left'>
                            <HomeBase redirect_uri={process.env.REACT_APP_HOMEBASE_URI || ""}/>
                        </div>
                        <div className='Point-operation-center'>
                            <Link to={`/`} className="Point-operation-return_button">保有ポイントを確認する</Link>
                        </div>
                        <div className='Point-operation-right'>
                            <button className="Point-operation-card_button"onClick={() => this.handleClick(this.state.data?.id, this.state.data?.owner_account_hash, this.state.data?.recipient_account_hash, this.state.data?.amount)}>{this.state.data.recipient_name}さんへポイントを送る<span className="Point-operation-card_button-icon"><FaPaperPlane /></span></button>  
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}