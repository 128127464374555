import React from 'react';

import { UserInfo } from '../components/UserInfo';
import { PointOperationList} from '../components/PointOperationsList';
import HomeBase from '../components/HomeBase';
// import { logout } from '../libs/Firebase';
import "../css/Home.css";

export class Home extends React.Component<{}, {}> {
    render() {
        return (
            <div className='Home-layout'>
                <div className='Home-card'>
                    <h1 className='Home-h1'>保有ポイント</h1>
                    <UserInfo />
                    <h1 className='Home-h1'>ポイントを送る</h1>
                    <PointOperationList />
                    {/*<button onClick={logout} className='Home-logout'>ログアウト</button><br />*/}
                    <HomeBase redirect_uri={process.env.REACT_APP_HOMEBASE_URI || ""}/>
                </div>
            </div>
        );
    }
}
