import { DeployUtil, RuntimeArgs, CLValueBuilder, CLPublicKey } from "casper-js-sdk";
import { Buffer } from "buffer";

export const createTransferFromDeploy = function(owner_account_hash: string, recipient_account_hash: string, amount: number): DeployUtil.Deploy {
    // システムアカウントのPublic Key
    const system_publicKey = CLPublicKey.fromHex(process.env.REACT_APP_CASPER_SYSTEM_PUBLIC_KEY_HEX || "");
    const deployParams = new DeployUtil.DeployParams(
        system_publicKey,
        process.env.REACT_APP_CASPER_CHAIN_NAME || "",
        Number(process.env.REACT_APP_CASPER_GAS_PRICE || 1)
    )

    // Public Keyのみ必要
    return DeployUtil.makeDeploy(
        deployParams,
        DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            Uint8Array.from(Buffer.from(process.env.REACT_APP_CASPER_CONTRACT_HASH || "", "hex")),
            "transfer_from",
            RuntimeArgs.fromMap({
                owner: CLValueBuilder.byteArray(Uint8Array.from(Buffer.from(owner_account_hash, "hex"))),
                recipient: CLValueBuilder.byteArray(Uint8Array.from(Buffer.from(recipient_account_hash, "hex"))),
                amount: CLValueBuilder.i64(amount),
            })
        ),
        DeployUtil.standardPayment(
            Number(process.env.REACT_APP_CASPER_CONTRACT_FEE_TRANSFER || 80_000_000)
        )
    );
}