import React from 'react';
import { loginWithGoogle } from '../libs/Firebase';

export class Login extends React.Component<{}, {}> {
    async componentDidMount() {
        loginWithGoogle();
    }

    render() {
        return (
            <h1>ログイン中...</h1>
            //<button onClick={loginWithGoogle}>Googleでログイン</button>
        );
    }
}