import React from 'react';

import { getUserInfo } from '../libs/Firebase';
import '../css/UserInfo.css';

interface UserInfoState {
    data: {
        id: string;
        email: string;
        point: number;
    } | null;
}

export class UserInfo extends React.Component<{}, UserInfoState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null
        };
    }

    async componentDidMount() {
        //getUserInfo関数はPromise<any>を返すので、実行後にデータをStateに保管する
        const user_info = await getUserInfo();
        this.setState({
            data: user_info
        });
    }

    render() {
        if (this.state.data === null) {
            //画面描画情報を取得する前
            return (
                <p>ユーザ情報を読込中...</p>
            );
        }
        return (
            <div>
                メールアドレス<span className='user-info-span'>●</span>{this.state.data.email}<br />
                保有ポイント<span className='user-info-span'>●</span>{this.state.data.point}<br />
            </div>
        );
    }
}