import React from 'react';
import { Link } from 'react-router-dom';
import { DeployUtil } from 'casper-js-sdk';

import { listPointOperations } from '../libs/Firebase';
import { deployByUser } from '../libs/Firebase';
import {format} from 'date-fns';
import * as utils from '../libs/CasperUtils';
import '../css/PointOperationsList.css';

interface PointOperationListState {
    data: {
        //使用する項目のみ定義
        id: string,
        operation_datetime: string,
        action_code: string,
        owner: number,
        owner_name: string,
        owner_account_hash: string,
        recipient: number,
        recipient_name: string,
        recipient_account_hash: string,
        amount: number,
        service_code: string,
        service_event_id: string,
        service_data_hash: string,
        deploy_id: string
    }[] | null;
}

export class PointOperationList extends React.Component<{}, PointOperationListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
        };
    }

    async componentDidMount() {
        const point_operaions = await listPointOperations();
        this.setState({
            data: point_operaions
        });
    }

    async handleClick(id: string, owner_account_hash: string, recipient_account_hash: string, amount: number) {
        //パラメータのチェック
        if(!id || !owner_account_hash || !recipient_account_hash) {
            const message = "パラメータが不正です";
            alert(message);
            throw new Error(message);
        }

        const deploy = utils.createTransferFromDeploy(owner_account_hash, recipient_account_hash, amount);
        const deploy_json = JSON.stringify(DeployUtil.deployToJson(deploy));

        const result = await deployByUser(id, deploy_json);
        alert(`ポイントを送信しました ID=[${result.id}]`)
    }

    render(){
        if (this.state.data === null) {
            //画面描画情報を取得する前
            return (
                <p>ポイント処理情報を読込中...</p>
            );
        }
        return(
            <div>
                {this.state.data.length < 1 ? (
                    <div>送信予定のポイントはありません</div>
                ): this.state.data.map((item, index) => 
                    <div className='Point-operations-list' key = {index}>
                        <div className='Point-operations-list-item'>{item.recipient_name}&nbsp;さん</div>
                        <div className='Point-operations-list-item Point-operations-list_circle'>●</div>
                        <div className='Point-operations-list-item'>{format(Date.parse(item.operation_datetime), "yyyy/MM/dd HH:mm")}</div>
                        <div className='Point-operations-list-item'><Link to={`/operations/${item.id}`} className='Point-operations-list-arrow Point-operations-list-detail'>詳細画面へ</Link></div>
                        <div className='Point-operations-list-item'><button style={{}} className='Point-operations-list-button' onClick={() => this.handleClick(item.id, item.owner_account_hash, item.recipient_account_hash, item.amount)}>送信する</button></div>
                    </div>
                )}
            </div>
        );
    }
}
