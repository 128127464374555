import React from 'react';
import firebase from 'firebase/compat/app';
import LoadingOverlay from 'react-loading-overlay-ts';

import { onAuthStateChanged, FirebaseContext } from '../libs/Firebase';
import { Login } from './Login';

interface AuthState {
    checked: boolean,
    user: firebase.User | null,
}
interface AuthProps {
    children: React.ReactNode,
}

export class Auth extends React.Component<AuthProps, AuthState> {
    constructor(props: AuthProps) {
        super(props);
        this.state = {
            checked: false,
            user: null,
        }
    }

    componentDidMount() {
        onAuthStateChanged(async (user) => {
            if(user !== null) {
                //ログインしていれば、ユーザ情報をStateへ設定する
                this.setState({
                    checked: true,
                    user: user
                });
            } else {
                //ログインしていなければ、ログイン画面へリダイレクト
                //await loginWithGoogle();
                this.setState({
                    checked: true,
                    user: null
                });
            }
        });
    }

    render() {
        //ログインチェック前
        if(!this.state.checked) {
            //ローディング画面を表示
            return (
                <LoadingOverlay
                    active={true}
                    spinner
                    text='Loading...'
                >
                    <div style={{ height: '100vh', width: '100vw' }}></div>
                </ LoadingOverlay>
            );
        }

        //ログインチェック後
        if(this.state.user !== null) {
            //ログイン済み (state.user に値が設定されている) -> 子要素をそのままロードする
            return (
                <FirebaseContext.Provider value = {this.state.user}>
                    {this.props.children}
                </FirebaseContext.Provider>
                );
        } else {
            //未ログイン -> ログイン処理
            return (<Login />);
        }
    }
}
